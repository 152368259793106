

























import { FormHelper, Toast } from '@/mixins'
import { Component, Mixins } from 'vue-property-decorator'
import { Admin } from '@/api'

type UserBanInfo = {
  id: string
  username: string
}

@Component
export default class BanModal extends Mixins(FormHelper, Toast) {
  isOpen = false
  userInfos: UserBanInfo = {} as UserBanInfo
  reason = ''

  $refs!: {
    banForm: HTMLFormElement
  }
  
  async mounted() {
    this.$root.$on('openBanModal', (userInfos) => {
      this.isOpen = true
      this.userInfos = userInfos
    })
  }

  async ban(e) {
    e.preventDefault() // disable auto closing on OK

    const isFormValid = await this.$refs.banForm.validate()

    if (!isFormValid) return

    try {
      await Admin.banUser(this.userInfos.id, this.reason)

      this.showSuccessToast({
        title: 'Successfully banned!',
        message: `${this.userInfos.username} has been banned.`
      })
      this.$root.$emit('refreshUserList')
      this.isOpen = false
    } catch(e) {
      console.error('Error when trying to ban user', e)
    }
  }
}
